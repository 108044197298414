@import '../color-variable';

// Dark color mode variables
//
// Custom variables for the `[data-bs-theme="dark"]` theme. Use this as a starting point for your own custom color modes by creating a new theme-specific file like `_variables-dark.scss` and adding the variables you need.

//
// Global colors
//

// scss-docs-start sass-dark-mode-vars
// scss-docs-start theme-text-dark-variables
$primary-text-emphasis-dark:        tint-color($primary, 40%);
$secondary-text-emphasis-dark:      tint-color($secondary, 40%);
$success-text-emphasis-dark:        tint-color($success, 40%);
$info-text-emphasis-dark:           tint-color($info, 40%);
$warning-text-emphasis-dark:        tint-color($warning, 40%);
$danger-text-emphasis-dark:         tint-color($danger, 40%);
$light-text-emphasis-dark:          $gray-100;
$dark-text-emphasis-dark:           $gray-300;
// scss-docs-end theme-text-dark-variables

// scss-docs-start theme-bg-subtle-dark-variables
$primary-bg-subtle-dark:            rgba($primary, 0.25);
$secondary-bg-subtle-dark:          rgba($secondary, 0.25);
$success-bg-subtle-dark:            rgba($success, 0.25);
$info-bg-subtle-dark:               rgba($info, 0.25);
$warning-bg-subtle-dark:            rgba($warning, 0.25);
$danger-bg-subtle-dark:             rgba($danger, 0.25);
$light-bg-subtle-dark:              $gray-800;
$dark-bg-subtle-dark:               rgba($dark, 0.25);
// scss-docs-end theme-bg-subtle-dark-variables

// scss-docs-start theme-border-subtle-dark-variables
$primary-border-subtle-dark:        shade-color($primary, 40%);
$secondary-border-subtle-dark:      shade-color($secondary, 40%);
$success-border-subtle-dark:        shade-color($success, 40%);
$info-border-subtle-dark:           shade-color($info, 40%);
$warning-border-subtle-dark:        shade-color($warning, 40%);
$danger-border-subtle-dark:         shade-color($danger, 40%);
$light-border-subtle-dark:          $gray-700;
$dark-border-subtle-dark:           $gray-800;
// scss-docs-end theme-border-subtle-dark-variables

$body-color-dark:                   #a6b0cf;
$body-bg-dark:                      #222736;
$body-secondary-color-dark:         #c3cbe4;
$body-secondary-bg-dark:            #2a3042;
$body-tertiary-color-dark:          #f6f6f6;
$body-tertiary-bg-dark:             #32394e;
$body-emphasis-color-dark:          $white;
$border-color-dark:                 #32394e;
$border-color-translucent-dark:     #353d55;
$headings-color-dark:               #f6f6f6;
$link-color-dark:                   $primary;
$link-hover-color-dark:             shift-color($link-color-dark, -$link-shade-percentage);
$code-color-dark:                   $code-color;


//
// Forms
//

$form-select-indicator-color-dark:  $body-color-dark;
$form-select-indicator-dark:        url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$form-select-indicator-color-dark}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/></svg>");

$form-switch-color-dark:            rgba($white, .25);
$form-switch-bg-image-dark:         url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-color-dark}'/></svg>");

// scss-docs-start form-validation-colors-dark
$form-valid-color-dark:             $green-300;
$form-valid-border-color-dark:      $green-300;
$form-invalid-color-dark:           $red-300;
$form-invalid-border-color-dark:    $red-300;
// scss-docs-end form-validation-colors-dark


//
// Accordion
//

$accordion-icon-color-dark:         $primary-text-emphasis-dark;
$accordion-icon-active-color-dark:  $primary-text-emphasis-dark;

$accordion-button-icon-dark:         url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-color-dark}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
$accordion-button-active-icon-dark:  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-active-color-dark}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
// scss-docs-end sass-dark-mode-vars

/* =============================Custom Variables========================== */

$header-bg-dark:                        #262b3c;
$header-item-color-dark:                #a6b0cf;

//topbra dark
$header-dark-bg-dark:                   $primary;
$header-dark-item-color-dark:           #e9ecef;
$topbar-search-bg-dark:                 #2a3042;

// Footer
$footer-bg-dark:                        #262b3c;
$footer-color-dark:                     #a6b0cf;

// Horizontal nav
$topnav-bg-dark:                        #282e3f;
$menu-item-color-dark:                  #a6b0cf;
$menu-item-active-color-dark:           #556ee6;

$boxed-body-bg-dark:                    #32394f;


// Display
$display-none-dark:                                        block;
$display-block-dark:                                       none;
