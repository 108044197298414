// Color system

// scss-docs-start gray-color-variables
$white:    #fff;
$gray-100: #f8f9fa;
$gray-200: #eff2f7;
$gray-300: #f6f6f6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #74788d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black:    #000;
// scss-docs-end gray-color-variables

// fusv-disable
// scss-docs-start gray-colors-map
$grays: (
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900
);
// scss-docs-end gray-colors-map
// fusv-enable
// scss-docs-start color-variables
$blue:    #6e90f6;
$indigo:  #564ab1;
$purple:  #6f42c1;
$pink:    #e83e8c;
$red:     #f46a6a;
$orange:  #f1734f;
$yellow:  #f1b44c;
$green:   #34c38f;
$teal:    #050505;
$cyan: #fdfdfd;
// scss-docs-end color-variables

// scss-docs-start color-variables
$white-bg:  #ffffff;
// scss-docs-end color-variables

// scss-docs-start colors-map
$colors: (
  "blue":       $blue,
  "indigo":     $indigo,
  "purple":     $purple,
  "pink":       $pink,
  "red":        $red,
  "orange":     $orange,
  "yellow":     $yellow,
  "green":      $green,
  "teal":       $teal,
  "cyan":       $cyan,
  "black":      $black,
  "white":      $white,
  "gray":       $gray-600,
  "gray-dark":  $gray-800
);
// scss-docs-end colors-map

// scss-docs-start theme-color-variables
$primary:       $blue;
$secondary:     $gray-600;
$success:       $green;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$light:         $gray-200;
$dark:          $gray-800;
// scss-docs-end theme-color-variables

// Vertical Sidebar  Light
$sidebar-bg:                                          $white-bg;
$sidebar-menu-item-color:                             #545a6d;
$sidebar-menu-sub-item-color:                         #545a6d;
$sidebar-menu-item-icon-color:                        #7f8387;
$sidebar-menu-item-hover-color:                       #383c40;
$sidebar-menu-item-active-color:                      #556ee6;
$sidebar-width:                                       250px;
$sidebar-collapsed-width:                             70px;
$sidebar-width-sm:                                    160px;

// Vertical Sidebar - Dark
$sidebar-dark-bg:                                     #2a3042; //2c313a
$sidebar-dark-menu-item-color:                        #a6b0cf;
$sidebar-dark-menu-sub-item-color:                    #79829c;
$sidebar-dark-menu-item-icon-color:                   #6a7187;
$sidebar-dark-menu-item-hover-color:                  $white-bg;
$sidebar-dark-menu-item-active-color:                 $white-bg;

// Topbar - Default Light
$header-height:                                       70px;
$header-bg:                                           $white;
$header-item-color:                                   #555b6d;

// Topbar - Dark
$header-dark-bg:                                      #2a3042;
$header-dark-item-color:                              #e9ecef;

// Topbar Search
$topbar-search-bg:                                    #f3f3f9;

// Footer
$footer-height:                                       100px;
$footer-bg:                                           #f2f2f5;
$footer-color:                                        #74788d;

// Horizontal nav
$topnav-bg:                                           $white;

$menu-item-color:                                     #545a6d;
$menu-item-active-color:                              #556ee6;

// Right Sidebar
$rightbar-width:                                      280px;

// Display
$display-none:                                        none;
$display-block:                                       block;

// Brand
$navbar-brand-box-width:                             250px;

// Boxed layout width
$boxed-layout-width:                                 1300px;
$boxed-body-bg:                                     #ebebf4;

// table
$table-head-bg:                                     $gray-100;
$table-dark-border-color:                           tint-color($gray-800, 7.5%);
